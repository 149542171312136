import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type TSeoProps = {
  title: string
  description?: string
  keywords?: string
}

const Seo: FC<TSeoProps> = ({
  title,
  description = undefined,
  keywords = undefined,
}) => {
  const { t } = useTranslation('seo')
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:title" content={title} />
      <meta name="description" content={description || t('home.desc')} />
      <meta name="og:description" content={description || t('home.desc')} />
      <meta
        name="og:image"
        content="https://numideal.com/img/opengraph-image.jpg"
      />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
    </Helmet>
  )
}

export default Seo
