import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Modal from '../reusableComponents/Modal'
import useMeQuery from '../../api/query/useMeQuery'
import { CUSTOMER_GROUP } from '../../utils/constants'
import useBoundStore from '../../slices/useBoundStore'

const ActivateSellerModalContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <span className="font-bold">{t('user.modal.conditions')}</span>
      <ul className="mb-6 mt-4 list-disc">
        <li className="ml-4 pl-2">{t('user.modal.cond_1')}</li>
        <li className="ml-4 pl-2">{t('user.modal.cond_2')}</li>
        <li className="ml-4 pl-2">{t('user.modal.cond_3')}</li>
      </ul>
      <span className="font-bold">{t('user.modal.recommendation')}</span>
      <ul className="mb-6 mt-4 list-disc">
        <li className="ml-4 pl-2">{t('user.modal.rec_1')}</li>
        <li className="ml-4 pl-2">{t('user.modal.rec_2')}</li>
      </ul>
      <p>
        <Trans
          i18nKey="user.modal.final_desc"
          components={{ bold: <span className="font-bold" /> }}
        />
      </p>
    </>
  )
}

const SellerActivationModals = () => {
  const { data: me } = useMeQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sellerActivationModalOpened, setSellerActivationModalOpened } =
    useBoundStore()

  return me ? (
    <>
      {me.group === CUSTOMER_GROUP && !me.seller_requested && (
        <Modal
          isOpen={sellerActivationModalOpened}
          title={t('user.modal.want_to_become_seller')}
          description={<ActivateSellerModalContent />}
          onClose={(opened) => setSellerActivationModalOpened(opened)}
          onAccept={() => {
            navigate(`/${t('urls.register_seller')}`)
            setSellerActivationModalOpened(false)
          }}
        />
      )}

      {me.group === CUSTOMER_GROUP && me.seller_requested && (
        <Modal
          isOpen={sellerActivationModalOpened}
          title={t('user.modal.waiting_for_seller_activation')}
          description={
            <p>{t('user.modal.waiting_for_seller_activation_desc')}</p>
          }
          onClose={(opened) => setSellerActivationModalOpened(opened)}
          closeButtonText={t('user.modal.back_to_profile')}
        />
      )}
    </>
  ) : null
}

export default SellerActivationModals
