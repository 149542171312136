import React, { FC, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import i18next from 'i18next'
import RoutesManager from './components/RoutesManager'

import ScrollToTop from './components/ScrollToTop'
import SellerActivationModals from './components/modals/SellerActivationModals'
import Seo from './components/reusableComponents/Seo'
// import Loader from './components/reusableComponents/Loader'

const CookieProvider = React.lazy(
  () => import('./components/cookies/CookieProvider')
)
const CookieModal = React.lazy(() => import('./components/cookies/CookieModal'))
const CookieBar = React.lazy(() => import('./components/cookies/CookieBar'))
const Header = React.lazy(() => import('./components/Header'))
const Footer = React.lazy(() => import('./components/Footer'))

// App without react-router, useful for testing
const App: FC = () => {
  return (
    <CookieProvider>
      <Header />
      <div className="min-h-[80vh]">
        <RoutesManager />
        <CookieBar />
        <CookieModal />
        <SellerActivationModals />
      </div>
      <Footer />
    </CookieProvider>
  )
}

export const WrappedApp = () => {
  useEffect(() => {
    if (i18next.resolvedLanguage) {
      const pt = window.location.pathname.match(/\/([a-z]{2})/)
      if (!pt || pt.length < 2 || pt[1] !== i18next.resolvedLanguage) {
        window.location.href = `${window.location.origin}/${i18next.resolvedLanguage}`
      } else document.documentElement.lang = i18next.resolvedLanguage
    }
  }, [])

  return (
    <BrowserRouter basename={`${i18next.resolvedLanguage}`}>
      <HelmetProvider>
        <Seo
          title={i18next.t('seo:home.title')}
          description={i18next.t('seo:home.desc')}
        />
        <ScrollToTop />
        <App />
      </HelmetProvider>
    </BrowserRouter>
  )
}

export default App
