import { StateCreator } from 'zustand'
import { ZodError } from 'zod'
import type { TCombinedTypes } from './useBoundStore'
import {
  TSellerAddressScheme,
  TCustomerAddressScheme,
} from '../validations/createOrder'

export interface TOrderSlice {
  sellerBilling: TSellerAddressScheme
  sellerDelivery: TCustomerAddressScheme
  customerBilling: TCustomerAddressScheme
  customerDelivery: TCustomerAddressScheme
  deliverToDifferentAddress: boolean
  paymentMethod: string
  billingErrors: ZodError
  deliveryErrors: ZodError
  setSellerBilling: (newValues: TSellerAddressScheme) => void
  setSellerDelivery: (newValues: TCustomerAddressScheme) => void
  setCustomerBilling: (newValues: TCustomerAddressScheme) => void
  setCustomerDelivery: (newValues: TCustomerAddressScheme) => void
  setDeliveryToDifferentAddress: (newValue: boolean) => void
  setBillingErrors: (newValues: ZodError) => void
  setDeliveryErrors: (newValues: ZodError) => void
  // setPaymentMethod: (paymentMethod: string) => void
}

const createOrderSlice: StateCreator<TCombinedTypes, [], [], TOrderSlice> = (
  set
) => ({
  sellerBilling: {
    company: '',
    company_id: '',
    phone: '',
    street: '',
    houseNumber: '',
    city: '',
    postCode: '',
    country: 0,
  },
  sellerDelivery: {
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    houseNumber: '',
    city: '',
    postCode: '',
    country: 0,
  },
  customerBilling: {
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    houseNumber: '',
    city: '',
    postCode: '',
    country: 0,
    company: '',
  },
  customerDelivery: {
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    houseNumber: '',
    city: '',
    postCode: '',
    country: 0,
    company: '',
  },
  deliverToDifferentAddress: false,
  paymentMethod: 'ps_wirepayment',
  billingErrors: ZodError.create([]),
  deliveryErrors: ZodError.create([]),
  setSellerBilling: (newValues) => set(() => ({ sellerBilling: newValues })),
  setSellerDelivery: (newValues) => set(() => ({ sellerDelivery: newValues })),
  setCustomerBilling: (newValues) =>
    set(() => ({ customerBilling: newValues })),
  setCustomerDelivery: (newValues) =>
    set(() => ({ customerDelivery: newValues })),
  setBillingErrors: (newValues) => set(() => ({ billingErrors: newValues })),
  setDeliveryErrors: (newValues) => set(() => ({ deliveryErrors: newValues })),
  setDeliveryToDifferentAddress: (newValue) =>
    set(() => ({ deliverToDifferentAddress: newValue })),
})

export default createOrderSlice
