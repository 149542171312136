import { StateCreator } from 'zustand'
import type { TCombinedTypes } from './useBoundStore'

export interface TCatalogSlice {
  page: number
  searchQuery: string | undefined
  setPage: (newPage: number) => void
  setSearchQuery: (query: string) => void
  resetSearchQuery: () => void
}

const createCatalogSlice: StateCreator<
  TCombinedTypes,
  [],
  [],
  TCatalogSlice
> = (set) => ({
  page: 1,
  searchQuery: undefined,
  setPage: (newPage) => set(() => ({ page: newPage })),
  setSearchQuery: (query) => set({ searchQuery: query }),
  resetSearchQuery: () => set({ searchQuery: undefined }),
})

export default createCatalogSlice
