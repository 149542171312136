import { useQuery } from '@tanstack/react-query'
import { api } from '../index'
import type { TBECustomer, TBESeller } from '../../@types/backend/user'
import { USE_SELLER_MOCK } from '../../utils/constants'

const useMeQuery = (enabled = true) => {
  const useMock = false

  return useQuery({
    queryKey: ['me'],
    queryFn: (): Promise<TBECustomer | TBESeller> => {
      return useMock
        ? new Promise((res) => {
            res(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              USE_SELLER_MOCK
                ? import('../../assets/mock/sellerMe.json')
                : import('../../assets/mock/customerMe.json')
            )
          })
        : api({ handledCodes: [401] })
            .get(`customer/me`)
            .json<TBECustomer | TBESeller>()
    },
    enabled,
  })
}

export default useMeQuery
