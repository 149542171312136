import { FC, MouseEvent, ReactElement } from 'react'
import clsx from 'clsx'

type TButton = {
  text: string | ReactElement
  classNames?: string
  variant?: 'bronze' | 'grey' | 'darkRed'
  size?: 'small' | 'medium' | 'big'
  onClick?: (event: MouseEvent<HTMLButtonElement, Event>) => void
  disabled?: boolean
  disabledText?: string | ReactElement
  loading?: boolean
  outline?: boolean
}

const Button: FC<TButton> = ({
  text,
  classNames = '',
  variant = 'bronze',
  size = 'medium',
  onClick = undefined,
  disabled = false,
  disabledText = undefined,
  loading = false,
  outline = false,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        `my-4 border px-3 font-semibold sm:px-8`,
        {
          'py-4': size === 'big',
          'py-3': size === 'medium',
          'py-2.5': size === 'small',
          'border-bronze bg-bronze text-white':
            variant === 'bronze' && !outline,
          'border-white-darker bg-white-darker text-bronze':
            variant === 'grey' && !outline,
          'border-red-darker bg-red-darker text-white':
            variant === 'darkRed' && !outline,
          'border-bronze bg-white text-bronze': variant === 'bronze' && outline,
          'border-bronze bg-bronze text-white-darker':
            variant === 'grey' && outline,
          'border-red-darker bg-white text-red-darker':
            variant === 'darkRed' && outline,
          'cursor-default items-center justify-center': loading,
          'hover:brightness-110': !loading,
          'cursor-not-allowed disabled:border-grey disabled:bg-grey disabled:text-black disabled:hover:brightness-100':
            disabled,
        },
        classNames
      )}
      onClick={(event) =>
        onClick && !disabled && !loading ? onClick(event) : null
      }
      disabled={disabled}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {disabled && disabledText ? (
        disabledText
      ) : loading ? (
        <div
          className={clsx('button-loader', {
            inverse: outline,
          })}
        >
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
        </div>
      ) : (
        text
      )}
    </button>
  )
}

export default Button
