import { FC, Fragment, ReactElement } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '../../assets/icons/close_icon.svg?react'
import Button from './Button'

type TModalProps = {
  isOpen: boolean
  title: string
  description?: string | ReactElement
  onClose: (opened: false) => void
  onAccept?: () => void
  onThirdAction?: () => void
  closeButtonText?: string
  acceptButtonText?: string
  thirdActionText?: string
  submitLoading?: boolean
}

const Modal: FC<TModalProps> = ({
  isOpen,
  title,
  description = undefined,
  onClose,
  onAccept = undefined,
  onThirdAction = undefined,
  closeButtonText = undefined,
  acceptButtonText = undefined,
  thirdActionText = undefined,
  submitLoading = false,
}) => {
  const { t } = useTranslation()

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center overflow-y-auto p-4 text-center">
              <Dialog.Panel className="w-full max-w-[600px] bg-white p-4 md:p-8">
                <Dialog.Title className="flex items-start justify-between">
                  <span className="text-left max-sm:text-[1.25rem]">
                    {title}
                  </span>
                  <CloseIcon
                    className="ml-2 h-[40px] cursor-pointer"
                    onClick={() => onClose(false)}
                  />
                </Dialog.Title>
                <div className="my-8 text-left text-xs 2xs:text-[15px] xs:text-base">
                  {description}
                </div>

                <div className="flex flex-col items-stretch justify-end sm:flex-row sm:items-center">
                  <Button
                    onClick={() => onClose(false)}
                    text={closeButtonText || t('common.cancel')}
                    outline
                  />

                  {onThirdAction && thirdActionText && (
                    <button
                      type="button"
                      className="border border-bronze px-8 py-3 font-semibold text-bronze hover:bg-bronze hover:text-white sm:ml-3"
                      onClick={() => onThirdAction()}
                    >
                      {thirdActionText}
                    </button>
                  )}

                  {onAccept ? (
                    <Button
                      loading={submitLoading}
                      classNames="min-w-[150px] hover:brightness-110 sm:ml-3"
                      onClick={() => {
                        onAccept()
                      }}
                      text={acceptButtonText || t('common.continue')}
                    />
                  ) : null}
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default Modal
