import { StateCreator } from 'zustand'
import type { TCombinedTypes } from './useBoundStore'

export const supportedCurrencies = [
  { id: 2, name: 'CZK', sign: 'Kč' },
  { id: 1, name: 'EUR', sign: '€' },
] as const
type TSupportedCurrencies = (typeof supportedCurrencies)[number]

export interface TGlobalSlice {
  currency: TSupportedCurrencies
  sellerActivationModalOpened: boolean
  setCurrency: (newCurrency: TSupportedCurrencies) => void
  setSellerActivationModalOpened: (opened: boolean) => void
}

const createGlobalSlice: StateCreator<TCombinedTypes, [], [], TGlobalSlice> = (
  set
) => ({
  currency: supportedCurrencies[1],
  sellerActivationModalOpened: false,
  setCurrency: (newCurrency) => set(() => ({ currency: newCurrency })),
  setSellerActivationModalOpened: (opened) =>
    set(() => ({ sellerActivationModalOpened: opened })),
})

export default createGlobalSlice
